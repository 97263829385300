// CAUTION: Do not edit this file. It has been generated automatically.
// NOTE: You can add more icons by editing config/fontawesome.yml and
// running `bin/fontawesome` after.
import { config, dom, library } from '@fortawesome/fontawesome-svg-core'
import { faCheck as falCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faCheckCircle as falCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle'
import { faCheckDouble as falCheckDouble } from '@fortawesome/pro-light-svg-icons/faCheckDouble'
import { faCircle as falCircle } from '@fortawesome/pro-light-svg-icons/faCircle'
import { faCog as falCog } from '@fortawesome/pro-light-svg-icons/faCog'
import { faCommentLines as falCommentLines } from '@fortawesome/pro-light-svg-icons/faCommentLines'
import { faHourglass as falHourglass } from '@fortawesome/pro-light-svg-icons/faHourglass'
import { faLifeRing as falLifeRing } from '@fortawesome/pro-light-svg-icons/faLifeRing'
import { faSignOut as falSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut'
import { faTimesCircle as falTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle'
import { faAngleDoubleLeft as farAngleDoubleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleLeft'
import { faAngleDoubleRight as farAngleDoubleRight } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleRight'
import { faAngleDown as farAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown'
import { faAngleLeft as farAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft'
import { faAngleRight as farAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight'
import { faAngleUp as farAngleUp } from '@fortawesome/pro-regular-svg-icons/faAngleUp'
import { faArrowCircleLeft as farArrowCircleLeft } from '@fortawesome/pro-regular-svg-icons/faArrowCircleLeft'
import { faBars as farBars } from '@fortawesome/pro-regular-svg-icons/faBars'
import { faBell as farBell } from '@fortawesome/pro-regular-svg-icons/faBell'
import { faBuilding as farBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding'
import { faBullseye as farBullseye } from '@fortawesome/pro-regular-svg-icons/faBullseye'
import { faCalendar as farCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar'
import { faCalendarAlt as farCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import { faCalendarCheck as farCalendarCheck } from '@fortawesome/pro-regular-svg-icons/faCalendarCheck'
import { faCaretDown as farCaretDown } from '@fortawesome/pro-regular-svg-icons/faCaretDown'
import { faChartLine as farChartLine } from '@fortawesome/pro-regular-svg-icons/faChartLine'
import { faCheck as farCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faCheckCircle as farCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle'
import { faChevronDown as farChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faChevronLeft as farChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faChevronRight as farChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faChevronUp as farChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp'
import { faClipboard as farClipboard } from '@fortawesome/pro-regular-svg-icons/faClipboard'
import { faClock as farClock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faCloudUpload as farCloudUpload } from '@fortawesome/pro-regular-svg-icons/faCloudUpload'
import { faCopy as farCopy } from '@fortawesome/pro-regular-svg-icons/faCopy'
import { faDownload as farDownload } from '@fortawesome/pro-regular-svg-icons/faDownload'
import { faEdit as farEdit } from '@fortawesome/pro-regular-svg-icons/faEdit'
import { faEllipsisV as farEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV'
import { faEnvelope as farEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faExclamationCircle as farExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'
import { faExclamationTriangle as farExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faExternalLink as farExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink'
import { faEye as farEye } from '@fortawesome/pro-regular-svg-icons/faEye'
import { faFile as farFile } from '@fortawesome/pro-regular-svg-icons/faFile'
import { faFileCheck as farFileCheck } from '@fortawesome/pro-regular-svg-icons/faFileCheck'
import { faFileDownload as farFileDownload } from '@fortawesome/pro-regular-svg-icons/faFileDownload'
import { faFileEdit as farFileEdit } from '@fortawesome/pro-regular-svg-icons/faFileEdit'
import { faFileExcel as farFileExcel } from '@fortawesome/pro-regular-svg-icons/faFileExcel'
import { faFileInvoice as farFileInvoice } from '@fortawesome/pro-regular-svg-icons/faFileInvoice'
import { faFilePlus as farFilePlus } from '@fortawesome/pro-regular-svg-icons/faFilePlus'
import { faFileUpload as farFileUpload } from '@fortawesome/pro-regular-svg-icons/faFileUpload'
import { faHourglass as farHourglass } from '@fortawesome/pro-regular-svg-icons/faHourglass'
import { faInfoCircle as farInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle'
import { faMapMarkerAlt as farMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt'
import { faMobile as farMobile } from '@fortawesome/pro-regular-svg-icons/faMobile'
import { faPencil as farPencil } from '@fortawesome/pro-regular-svg-icons/faPencil'
import { faPlus as farPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import { faSearch as farSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'
import { faShareAlt as farShareAlt } from '@fortawesome/pro-regular-svg-icons/faShareAlt'
import { faShoppingBasket as farShoppingBasket } from '@fortawesome/pro-regular-svg-icons/faShoppingBasket'
import { faTag as farTag } from '@fortawesome/pro-regular-svg-icons/faTag'
import { faTimes as farTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faTimesCircle as farTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle'
import { faToggleOff as farToggleOff } from '@fortawesome/pro-regular-svg-icons/faToggleOff'
import { faToggleOn as farToggleOn } from '@fortawesome/pro-regular-svg-icons/faToggleOn'
import { faTrashAlt as farTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import { faUndo as farUndo } from '@fortawesome/pro-regular-svg-icons/faUndo'
import { faUser as farUser } from '@fortawesome/pro-regular-svg-icons/faUser'
import { faAlarmClock as fasAlarmClock } from '@fortawesome/pro-solid-svg-icons/faAlarmClock'
import { faCaretDown as fasCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import { faCheckCircle as fasCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faChevronDown as fasChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faCircle as fasCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
import { faCog as fasCog } from '@fortawesome/pro-solid-svg-icons/faCog'
import { faEmptySet as fasEmptySet } from '@fortawesome/pro-solid-svg-icons/faEmptySet'
import { faSpinner as fasSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'
import { faUser as fasUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faUserCrown as fasUserCrown } from '@fortawesome/pro-solid-svg-icons/faUserCrown'
config.mutateApproach = 'sync' // Solve Turbo flickering
library.add(
  falCheck,
  falCheckCircle,
  falCheckDouble,
  falCircle,
  falCog,
  falCommentLines,
  falHourglass,
  falLifeRing,
  falSignOut,
  falTimesCircle,
  farAngleDoubleLeft,
  farAngleDoubleRight,
  farAngleDown,
  farAngleLeft,
  farAngleRight,
  farAngleUp,
  farArrowCircleLeft,
  farBars,
  farBell,
  farBuilding,
  farBullseye,
  farCalendar,
  farCalendarAlt,
  farCalendarCheck,
  farCaretDown,
  farChartLine,
  farCheck,
  farCheckCircle,
  farChevronDown,
  farChevronLeft,
  farChevronRight,
  farChevronUp,
  farClipboard,
  farClock,
  farCloudUpload,
  farCopy,
  farDownload,
  farEdit,
  farEllipsisV,
  farEnvelope,
  farExclamationCircle,
  farExclamationTriangle,
  farExternalLink,
  farEye,
  farFile,
  farFileCheck,
  farFileDownload,
  farFileEdit,
  farFileExcel,
  farFileInvoice,
  farFilePlus,
  farFileUpload,
  farHourglass,
  farInfoCircle,
  farMapMarkerAlt,
  farMobile,
  farPencil,
  farPlus,
  farSearch,
  farShareAlt,
  farShoppingBasket,
  farTag,
  farTimes,
  farTimesCircle,
  farToggleOff,
  farToggleOn,
  farTrashAlt,
  farUndo,
  farUser,
  fasAlarmClock,
  fasCaretDown,
  fasCheckCircle,
  fasChevronDown,
  fasCircle,
  fasCog,
  fasEmptySet,
  fasSpinner,
  fasUser,
  fasUserCrown
)
const css = dom.css()
dom.watch()
dom.insertCss(css)
